import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {serverUrl} from './config'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom"

const link = createHttpLink({
    uri: serverUrl,
    credentials: 'same-origin'
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link
});

ReactDOM.render(
    <ApolloProvider client={client}>
        <Router>
            <Switch>
                <Route path='/sale'
                       render={(props)=>
                           <App match = {props.match} page="list"/> }>
                </Route>
                <Route path='/stock/:sn'
                       render={(props)=>
                           <App match = {props.match}  page="item"/> }>
                </Route>
                <Route path='/cars/:sn'
                       render={(props)=>
                           <App match = {props.match}  page="item"/> }>
                </Route>
                <Route path='/for-sale/:param1/:param2/:param3/:sn'
                       render={(props)=>
                           <App match = {props.match}  page="item"/> }>
                </Route>
                <Route path='/buy-now/:param1/:param2/:param3/:sn'
                       render={(props)=>
                           <App match = {props.match}  page="item"/> }>
                </Route>
                <Route path='/request/:code'
                       render={(props)=>
                           <App match = {props.match}  page="request"/> }>
                </Route>
                <Route path='/update/:sn'
                       render={(props)=>
                           <App match = {props.match} update={1}/> }>
                </Route>
                <Route path='/favorites'
                       render={(props)=>
                           <App match = {props.match} favorites = {1}  page="favorites"/> }>
                </Route>
                <Route path='/sign-up'
                       render={(props)=>
                           <App match = {props.match} dealerSignUp = {1}/> }>
                </Route>
                <Route path='/sign-in'
                       render={(props)=>
                           <App match = {props.match} page="dealer-log-in"/> }>
                </Route>
                <Route path='/reservations'
                       render={(props)=>
                           <App match = {props.match} reservations = {1}/> }>
                </Route>
                <Route path='/inventory'
                       render={(props)=>
                           <App match = {props.match} inventory = {1}/> }>
                </Route>
                <Route path='/store'
                       render={(props)=>
                           <App match = {props.match} dealer = {1}/> }>
                </Route>
                <Route path='/reviews/*'
                       render={(props)=>
                           <App match = {props.match} reviews = {1}/> }>
                </Route>
                <Route path='/blog/*'
                       render={(props)=>
                           <App match = {props.match} blog = {1}/> }>
                </Route>
                <Route path='/used-cars*'
                       render={(props)=>
                           <App match = {props.match} list = {1} page="list"/> }>
                </Route>
                <Route path='/member/sign-up'
                       render={(props)=>
                           <App match = {props.match} page="membership-sign-up"/> }>
                </Route>
                <Route path='/member/login'
                       render={(props)=>
                           <App match = {props.match} page="membership-login"/> }>
                </Route>
                <Route path='/member/account'
                       render={(props)=>
                           <App match = {props.match} page="membership-account"/> }>
                </Route>
                <Route path='/member/remind-password-confirm*'
                       render={(props)=>
                           <App match = {props.match} page="membership-remind-confirm"/> }>
                </Route>
                <Route path='/member/remind-password'
                       render={(props)=>
                           <App match = {props.match} page="membership-remind"/> }>
                </Route>
                <Route path='/member/change-password'
                       render={(props)=>
                           <App match = {props.match} page="membership-change-password"/> }>
                </Route>
                <Route path='/member/update-account-information'
                       render={(props)=>
                           <App match = {props.match} page="membership-update-information"/> }>
                </Route>
                <Route path='/member/upgrade-membership'
                       render={(props)=>
                           <App match = {props.match} page="membership-upgrade-membership"/> }>
                </Route>
                <Route path='/member/logout'
                       render={(props)=>
                           <App match = {props.match} page="membership-logout"/> }>

                </Route>
                <Route path='/sell-your-car'
                       render={(props)=>
                           <App match = {props.match} page="sell-your-car"/> }>

                </Route>
                <Route path='/help'
                       render={(props)=>
                           <App match = {props.match} page="help"/> }>

                </Route>
                <Route path='/contact-us'
                       render={(props)=>
                           <App match = {props.match} page="contact-us"/> }>

                </Route>
                <Route path='/settings'
                       render={(props)=>
                           <App match = {props.match} page="settings"/> }>

                </Route>
                <Route path='/manager/leads'
                       render={(props)=>
                           <App match = {props.match} page="manager-leads"/> }>
                </Route>
                <Route path='/manager/call'
                       render={(props)=>
                           <App match = {props.match} page="manager-calls"/> }>
                </Route>
                <Route path='/manager/calls*'
                       render={(props)=>
                           <App match = {props.match} page="manager-calls"/> }>
                </Route>
                <Route exact path='/'
                       render={(props)=>
                           <App match = {props.match} list = {1} page="list"/> }>
                </Route>
                <Route path='/'
                       render={(props)=>
                           <App match = {props.match} lp="1"/> }>
                </Route>
            </Switch>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
);


